<template>
  <div class="edit-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/index' }">会议壳</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/meeting-list' }">会议列表</el-breadcrumb-item>
      <el-breadcrumb-item>{{this.$route.query.meetingName}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/content/index/' + this.$route.params.mId }">会议内容管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/content/clockin/' + this.$route.params.mId, query: { itemName: this.$route.query.meetingName } }">拍照打卡</el-breadcrumb-item>
      <el-breadcrumb-item>修改打卡点</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form :model="editForm" :rules="editRules" ref="editRef" class="edit-form" label-position="left" label-width="100px">
        <el-form-item label="排序" prop="sort">
          <el-input-number size="medium" v-model="editForm.sort" :min="0" :step-strictly="true"></el-input-number>
        </el-form-item>
        <el-form-item label="打卡点缩略图" prop="thumbnail">
          <upload :url="editForm.thumbnail" :filed="'thumbnail'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="滚动图" prop="banner">
          <upload :urlArr="editForm.banner" :filed="'banner'" :max-num="5" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="打卡点名称" prop="title">
          <el-input v-model="editForm.title"></el-input>
        </el-form-item>
        <el-form-item label="打卡点介绍" prop="desc">
          <QuillBar v-model="editForm.desc" :contenttext="editForm.desc" @inputChange="descChange"></QuillBar>
        </el-form-item>
        <el-form-item label="打卡上传权限" prop="upload_type">
          <el-radio-group v-model="editForm.upload_type">
            <el-radio :label="1">仅限指定人员</el-radio>
            <el-radio :label="2">所有人均可上传</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="地标坐标" prop="coord">
          <div class="flex-input">
            <el-input v-model="editForm.coord" placeholder="请点击右侧按钮选取坐标"></el-input>
            <coord-picker @configAddress="configAddress"></coord-picker>
          </div>
        </el-form-item>
        <el-form-item label="打卡点地址" prop="address">
          <el-input v-model="editForm.address"></el-input>
        </el-form-item>
        <el-form-item label="是否需要权限" prop="is_auth">
          <el-radio-group v-model="editForm.is_auth">
            <el-radio :label="1">需要</el-radio>
            <el-radio :label="2">不需要</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button @click="cancleForm">取消添加</el-button>
          <el-button type="primary" @click="submitForm('editRef')">确认修改</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Upload from '../../../../common/Upload'
import QuillBar from '../../../../common/QuillBar'
import CoordPicker from '../../../../common/CoordPicker'
export default {
  name: 'Edit',
  components: {
    Upload,
    QuillBar,
    CoordPicker
  },
  data () {
    return {
      editForm: {
        id: 0,
        sort: 0,
        thumbnail: '',
        banner: [],
        title: '',
        desc: '',
        upload_type: 1,
        address: '',
        coord: '',
        is_auth: 2
      },
      editRules: {
        title: [
          { required: true, message: '请填写打卡点名称', trigger: 'blur' },
          { min: 2, max: 30, message: '长度在 2 到 30 个字符', trigger: 'blur' }
        ],
        thumbnail: [
          { required: true, message: '请上传打卡点缩略图', trigger: 'blur' }
        ],
        coord: [
          { required: true, message: '请选择地标坐标', trigger: 'blur' }
        ]
      },
      quill: null,
      editorOption: {
        placeholder: '请输入内容',
        theme: 'snow',
        modules: {}
      }
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    // 获取被修改信息
    async getInfo () {
      const { data: res } = await this.$http.get('/shell-clockin/' + this.$route.params.id)
      if (res.status === 200) {
        this.editForm.id = res.data.id
        this.editForm.sort = res.data.sort
        this.editForm.thumbnail = res.data.thumbnail
        this.editForm.banner = res.data.banner
        this.editForm.title = res.data.title
        this.editForm.desc = res.data.desc
        this.editForm.upload_type = res.data.upload_type
        this.editForm.address = res.data.address
        this.editForm.coord = res.data.coord
        this.editForm.is_auth = Number(res.data.is_auth)
      } else {
        this.$message.error(res.msg)
      }
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.editForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.editForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.editForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.editForm[field].splice(checkedIndex, 1)
      }
    },
    // 修改表单
    submitForm () {
      this.$refs.editRef.validate(async (valid, item) => {
        if (valid) {
          const { data: res } = await this.$http.put('/shell-clockin/' + this.$route.params.id, this.editForm)
          if (res.status === 200) {
            this.$message.success(res.msg)
            this.$refs.editRef.resetFields()
            await this.$router.push({ path: '/admin/apps/shell/content/clockin/' + this.$route.params.mId, query: { itemName: this.$route.query.meetingName } })
          }
        } else {
          this.$message.error('请检查表单必填项')
          return false
        }
      })
    },
    // 文章介绍
    descChange (value) {
      this.editForm.desc = value
    },
    // 取消添加
    cancleForm () {
      this.$router.push({ path: '/admin/apps/shell/content/clockin/' + this.$route.params.mId, query: { itemName: this.$route.query.meetingName } })
    },
    // 确定选择的坐标
    configAddress (coordAddress) {
      this.editForm.coord = coordAddress.lnglat
      this.editForm.address = coordAddress.address
    }
  }
}
</script>

<style scoped>
.edit-form{
  width: 650px;
  margin-left: auto;
  margin-right: auto;
}
.flex-input{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.flex-input .el-button{
  margin-left: 10px;
}
</style>
